<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="8" offset-md="2" class="gray-background">
            <div class="p-10">
              <table class="width-100">
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Street 1
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.address_line_1 || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Street 2
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.address_line_2 || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Unit no.
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.unit_number || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Postal Code
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.postal_code || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Country</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ getCountryName || " - " }}
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "engineer-address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }
      ]
    };
  },
  computed: {
    getCountryName() {
      if (
        this.lodash.isEmpty(this.engineer) === false &&
        this.lodash.isEmpty(this.engineer.country) === false
      ) {
        return this.engineer.country.name;
      }
      return null;
    }
  }
};
</script>
