<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="6" offset-md="3">
            <v-sheet
              class="width-100 text-right"
              elevation="15"
              height="100"
              width="100"
              :style="{
                'background-color': engineer.color,
                height: '300px',
                'border-radius': '10px'
              }"
            ></v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }
      ]
    };
  }
};
</script>
